<template>
	<div>
		<template>
      <v-data-table
        :headers="tables.competitions.headers"
				:items="competitions"
				sort-by="date"
				sort-desc
      >
			<template v-slot:top>
					<v-select
						:items="[{text: 'Csak lezárt versenyek', value: 1},{text: 'Csak lezáratlan versenyek (folyamatban lévők IS)', value: 0}]"
						item-text="text"
						item-value="value"
						v-model="isClosed"
						label="Versenyállapot"
						prepend-inner-icon="fa-check"
						@change="getCompetitions()"
					/>
			</template>
				<template v-slot:item.level="{item}">
					{{competitionLevelNames[item.level]}}
				</template>
				<template v-slot:item.status="{item}">
					{{competitionStatusNames[item.status]}}
				</template>
        <template v-slot:item.invitationalApplication="{item}">
          <v-icon :color="item.invitationalApplication? 'red accent-4' : 'success'">{{item.invitationalApplication? "fa-lock" : "fa-lock-open"}}</v-icon>

        </template>
				<template v-slot:item.view="{item}">
					<v-btn icon :to="{name: 'competitionInfo', params: {id: item.id}}"><v-icon>fa-eye</v-icon></v-btn>
				</template>
      </v-data-table>

	</template>
	</div>
</template>

<script>
	import router from '../router';
	import competitionLevelNames from '../plugins/competitionLevelNames';
	import roles_CompetitionLevels from '../plugins/roles-CompetitionLevels'
	import competitionStatusNames from '../plugins/competitionStatusNames'
	export default {
		data: function(){
			return {
				isClosed: 0,
				competitionLevelNames,
				competitionStatusNames,
				competitions: [],
        tables: {
          competitions: {
            headers: [
							{
                text: "Egyesület",
                align: "center",
                sortable: true,
                filterable: true,
                value: "club"
              },
              {
                text: "Verseny",
                align: "center",
                sortable: true,
                filterable: true,
                value: "name"
              },
              {
                text: "Leírás",
                align: "center",
                sortable: false,
                filterable: true,
                value: "description"
              },
              {
                text: "Időpont",
                align: "center",
                sortable: true,
                filterable: true,
                value: "date"
              },
							{
                text: "Helyszín",
                align: "center",
                sortable: false,
                filterable: true,
                value: "place"
              },
							{
								text: "Szint",
								align: "center",
								sortable: false,
								filterable: false,
								value: "level"
							},
							{
								text: "Állapot",
								align: "center",
								sortable: false,
								filterable: false,
								value: "status"
							},
              {
                text: "Meghívásos regisztráció",
                align: "center",
								sortable: false,
								filterable: false,
								value: "invitationalApplication"
              },
							{
								text: "Megtekintés",
								align: "center",
								sortable: false,
								filterable: false,
								value: "view"
							}
            ]
          }
        },
      }
		},
		computed: {

		},
		watch: {

		},
		methods: {
			getCompetitions: function() {
				this.axios({url: `competition/list/${this.isClosed}`, method: "GET"}).then((response) => {
	        if(response.data.success) {
						this.competitions = response.data.data.competitions.withInvitation.map(c => {
	            return {
	              ...c,
	              invitationalApplication: true
	            }
	          }).concat(response.data.data.competitions.withoutInvitation.map(c => {
	            return {
	              ...c,
	              invitationalApplication: false
	            }
	          }));
	        }
				})
			}
		},
		mounted(){
			this.getCompetitions();
		}
	}
</script>
