import roles from './roles'
import competitionLevels from './competitionLevels'


var roles_CompetitionLevels = [];


roles_CompetitionLevels[roles.trainer] = [/*competitionLevels.house*/]
roles_CompetitionLevels[roles.clubAdmin] = [/*competitionLevels.house*/competitionLevels.club]
roles_CompetitionLevels[roles.regionalCoordinator] = [/*competitionLevels.house*/ competitionLevels.club, competitionLevels.regional]
roles_CompetitionLevels[roles.nationalCoordinator] = [/*competitionLevels.house*/competitionLevels.club, competitionLevels.national];

export default roles_CompetitionLevels;
