import competitionLevels from './competitionLevels'

var competitionLevelNames = []

competitionLevelNames[competitionLevels.house] = "Házi"
competitionLevelNames[competitionLevels.club] = "Egyesületi"
competitionLevelNames[competitionLevels.regional] = "Regionális"
competitionLevelNames[competitionLevels.national] = "Országos"


export default competitionLevelNames;
