var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-data-table',{attrs:{"headers":_vm.tables.competitions.headers,"items":_vm.competitions,"sort-by":"date","sort-desc":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-select',{attrs:{"items":[{text: 'Csak lezárt versenyek', value: 1},{text: 'Csak lezáratlan versenyek (folyamatban lévők IS)', value: 0}],"item-text":"text","item-value":"value","label":"Versenyállapot","prepend-inner-icon":"fa-check"},on:{"change":function($event){return _vm.getCompetitions()}},model:{value:(_vm.isClosed),callback:function ($$v) {_vm.isClosed=$$v},expression:"isClosed"}})]},proxy:true},{key:"item.level",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.competitionLevelNames[item.level])+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.competitionStatusNames[item.status])+" ")]}},{key:"item.invitationalApplication",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.invitationalApplication? 'red accent-4' : 'success'}},[_vm._v(_vm._s(item.invitationalApplication? "fa-lock" : "fa-lock-open"))])]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{name: 'competitionInfo', params: {id: item.id}}}},[_c('v-icon',[_vm._v("fa-eye")])],1)]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }